@tailwind base;

/* Write your own custom base styles here */
/* Firefox */
* {
 scrollbar-width: 20px;
 scrollbar-color: rgba(155, 155, 155, 0.5);
 background: transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
  background-color: transparent ; 
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write your own custom component styles here */


/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

/* RESIZE CLASSES for react-resize */



input, button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  }

  input[type=text], input[type=button], div[type=button] {
    -webkit-appearance: none !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    }

  .helptips p {
    padding-top:5px !important;
    padding-bottom:5px !important;
  }
  
  .helptips a {
    text-decoration:underline !important;
  }
  
  .helptips a:hover {
    text-decoration:none !important;
  }
  
  .helptips strong {
    margin-top:10px !important;
  }
  
  .helptips hr {
    margin-top:10px !important;
    margin-bottom:10px !important;
  }
  
  .helptips ol li, .helptips ul li {
    list-style-type: circle !important;
    padding-left:10px !important;
    margin-left:10px !important;
    
  }

.reacttooltip {
  pointer-events: auto !important;
  opacity: 1 !important;

}
.reacttooltip:hover {
  visibility: visible !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
  background: red
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


/*REACT HORIZONTAL MENU SCROLLBAR*/
/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


/*menu shadow*/
.shadow-inner-r-white {
	   -webkit-box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.4);
	      -moz-box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.4);
             box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.4);
}

.shadow-inner-r-dark {
  -webkit-box-shadow: inset -7px 0 9px -7px rgba(0,0,0,1);
     -moz-box-shadow: inset -7px 0 9px -7px rgba(0,0,0,1);
          box-shadow: inset -7px 0 9px -7px rgba(0,0,0,1);
}
.shadow-l-dark {
  -webkit-box-shadow: -7px 0 9px -7px rgba(0,0,0,1);
  -moz-box-shadow: -7px 0 9px -7px rgba(0,0,0,1);
  box-shadow: -7px 0 9px -7px rgba(0,0,0,1);
}


/*markdown*/
.markdown {
  @apply text-sm;
}

.markdown li {
  @apply list-disc;
  @apply ml-5;
}

.markdown h1, .markdown h2, .markdown h3, .markdown h4 {
  @apply text-2xl; 
  @apply font-bold
}

.markdown a {
  @apply text-blue-400;
}

.markdown a:hover {
  @apply underline;
}

.markdown p img {
  height: 200;
}

.markdown p {
  margin-bottom: 10px;
}

/*popup arrows*/
.popup-content {  border-radius: 5px;  padding: 0.5rem;}
html[data-theme='dark'] .popup-content {  background-color: rgb(41, 45, 62);  color: #fff;  border: 1px solid #9a9595;}
html[data-theme='light'] .popup-content {  background-color: #fff;  color: #000;  border: 1px solid #d7d7d7;}
html[data-theme='dark'] .popup-arrow > svg {  color: rgb(41, 45, 62);  stroke-width: 2px;  stroke: #9a9595;  stroke-dasharray: 30px;  stroke-dashoffset: -54px;}
html[data-theme='light'] .popup-arrow > svg {  color: #fff;  stroke-width: 2px;  stroke: #d7d7d7;  stroke-dasharray: 30px;  stroke-dashoffset: -54px;}
.popup-content {  margin: auto;  background: rgb(255, 255, 255);  width: 50%;  padding: 5px;}
.popup-arrow {  color: rgb(255, 255, 255);}[role='tooltip'].popup-content {  width: 200px;  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;}
.popup-overlay {  background: rgba(0, 0, 0, 0.5);}[data-popup='tooltip'].popup-overlay {  background: transparent;}

/*filestack*/
.fsp-drop-pane__icon {display:none}
.fsp-drop-pane__text {font-style:italic;}
.fsp-drop-pane__text::after {
  content: "";
    display: block;
    background: url("/images/app/arrow-up-circle.svg") no-repeat ;
    width: 24px;
    height: 24px;
    float: left;
    margin: 0 6px 0 0;
}

/*drawing canvas*/
div.ignore-scroll {
  touch-action: none;
  -ms-touch-action: none;
  -touch-action: none;
  -webkit-overflow-scrolling: touch;
}